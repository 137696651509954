
import { Button } from 'antd';
import React from 'react';
import * as THREE from 'three';
import { CubeCamera, Object3D, Scene, Vector3, WebGLCubeRenderTarget } from 'three';
import { Skybox } from '../Renderer/Skybox';
import OrientationDataset from '../utils/OrientationDataset';
import PhotoSphere from '../utils/PhotoSphere';
import proj4 from 'proj4';
import { LngLat } from 'mapbox-gl';

// import vanillaJS Potree libs, /!\ would be best with proper ES6 import
// @ts-ignore
const Potree = window.Potree;
// @ts-ignore
const TWEEN = window.TWEEN;

enum CameraMode {
    FREE_CAM,
    PHOTO_SPHERE
}

interface Props {
mapRef: React.RefObject<any>,
}

interface State {
    cameraMode: CameraMode
    activePanorama: boolean
    photoSphere: PhotoSphere | null
}

export default class PointCloudWindow extends React.Component<Props, State> {
    potreeContainerDiv : any;
    viewer : any;
    guiLoaded = false;
    pointCloudCenter : Vector3;

    constructor(props : Props) {
        super(props);

        this.state = {
            cameraMode: CameraMode.FREE_CAM,
            activePanorama: false,
            photoSphere: null
        };

        this.potreeContainerDiv = React.createRef();

        this.pointCloudCenter = new Vector3(0, 0, 0);
    }


    componentDidMount() {

    }
    componentWillUnmount(): void {

    }


    async loadPointCloud(rootUrl : string) {
        console.debug("Beginning to load point cloud!");

        // initialize Potree viewer
        const viewerElem = this.potreeContainerDiv.current;
        this.viewer = new Potree.Viewer(viewerElem);

        const viewer = this.viewer;

        viewer.setEDLEnabled(true);
        viewer.setFOV(60);
        viewer.setPointBudget(1_000_000);
        viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
        viewer.loadSettingsFromURL();
        viewer.useHQ = true;
        this.enterFreeCamMode();
        // hard coding minnesota for now - will need to change this later
        console.log('the root url is ', rootUrl)
        if (rootUrl.includes('minessota') || rootUrl.includes('minnesota') ) {
            viewer.setLengthUnitAndDisplayUnit('m', 'in');
        }else {
            viewer.setLengthUnitAndDisplayUnit('ft', 'in');
        }
      

        if (this.guiLoaded) {
            document.getElementById("sidebar_root")?.remove();
            document.getElementsByClassName("potree_menu_toggle")[0].remove();
            this.guiLoaded = false;
        }

        if (!this.guiLoaded) {
            viewer.loadGUI(() => {
                viewer.setLanguage('en');
                $("#menu_appearance").next().show();
                // viewer.toggleSidebar();
            });

            this.guiLoaded = true;
        }

        // Load and add point cloud to scene
        const url = `${rootUrl}/metadata.json`; // do not forget to put your pointcloud data in the public/pointcloud/ folder
        await Potree.loadPointCloud(url).then((e : any) => {
            console.debug("Loaded PC succesfully!", e);
            const pointcloud = e.pointcloud;
            const material = pointcloud.material;
            // Grab the center
            // TODO actually get the true center
            this.pointCloudCenter = pointcloud.position;


            // material.activeAttributeName = "rgba";
            material.minSize = 2;
            material.size = 5;
            // material.pointSizeType = Potree.PointSizeType.FIXED
            viewer.scene.addPointCloud(pointcloud);
            // console.log('the viewer scene is ', viewer.scene)

            viewer.fitToScreen();
        }, (e : any) => console.error("ERROR: ", e));
    }

    moveToPosition(orientation : any, latLng: any, updateLocation: any) {
        const view = this.viewer.scene.view;
        // a vector 3 containing x y and z
        const position = new Vector3(orientation.x, orientation.y, orientation.z);
        const camera = this.viewer.scene.getActiveCamera();

        let positiveAngle = orientation.heading*(Math.PI/180);
        positiveAngle = positiveAngle%360;
        positiveAngle = positiveAngle > 0 ? positiveAngle : positiveAngle+360;

        updateLocation(latLng, 360 - orientation.heading);
        const radiansToRotate = THREE.MathUtils.degToRad( 360 - orientation.heading);

        const heading = new Vector3(0, 0, radiansToRotate);

        view.position.copy(position);
        view.lookAt(heading);
        view.position.copy(position);
    }

    addPhotoSpheres(locations : Vector3[]) {
        for (const loc of locations) {
            const geometry = new THREE.SphereGeometry( 0.25, 10, 10 );
            const material_new = new THREE.MeshBasicMaterial( { color: 0xffff00, transparent: true, opacity: 0.2 } );
            const sphere = new THREE.Mesh( geometry, material_new );
            sphere.position.set(loc.x, loc.y, loc.z);
            this.viewer.scene.scene.add( sphere );
        }

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevState.activePanorama !== this.state.activePanorama) {
            const viewer = this.viewer;
            if (this.state.activePanorama) {
                this.loadSkybox(this.state.photoSphere as PhotoSphere);
            } else {
                viewer.scene.scene.background = null;
            }
        
        }
        if (prevState.photoSphere !== this.state.photoSphere) {
            this.loadSkybox(this.state.photoSphere as PhotoSphere);
        }

    }

    enterPhotoSphereMode() {
        // console.log('we entered photosphere mode')
        const viewer = this.viewer;
        viewer.setControls( viewer.orbitControls );
        this.setState({ cameraMode: CameraMode.PHOTO_SPHERE });

        viewer.setEDLOpacity(0.25);
    }

    enterFreeCamMode() {
        const viewer = this.viewer;
        // console.log('we entered free cam mode')
        // Turn on earth controls from viewer
        viewer.setControls( viewer.earthControls );
        // Update camera mode in state
        this.setState({ cameraMode: CameraMode.FREE_CAM });
        // Remove our background image we use for orbit mode
        viewer.scene.scene.background = null;
        // Make our opacity full again.
        viewer.setEDLOpacity(1);
    }

    jumpToPhotoSphere(photoSphere : PhotoSphere) {
        // Are we in photosphere mode?
        this.setState({ photoSphere })
        if (this.state.cameraMode != CameraMode.PHOTO_SPHERE) {
            this.enterPhotoSphereMode();
        }
        const view = this.viewer.scene.view;
        // Goto axis
        view.position.copy(photoSphere.camera_pos);
        // Look at position
        view.lookAt(photoSphere.camera_target);
        // Goto position
        view.position.copy(photoSphere.camera_pos);
        // Anchor to axis
        view.radius = -1 * Math.abs(photoSphere.camera_pos.distanceTo(photoSphere.camera_target));

        this.setState({  activePanorama: true })

        const freeCamMoveEvent = new CustomEvent("on-free-cam-moved", {
            detail: {
                position: view.position,
                rotation: this.viewer.scene.getActiveCamera().rotation,
            },
        });
        document.getElementById("event_listener")?.dispatchEvent(freeCamMoveEvent);
    }


    /**
     *
     * @param photoSphere The photosphere we are loading.
     */
    loadSkybox(photoSphere: PhotoSphere) {
        const viewer = this.viewer;
    
        // Remove the background so we don't confuse the user.
        if (viewer.scene.scene.background) {
            // Dispose of the existing background texture if any
            if (viewer.scene.scene.background instanceof THREE.WebGLRenderTarget) {
                viewer.scene.scene.background.texture.dispose();
                viewer.scene.scene.background.dispose();
            } else if (viewer.scene.scene.background instanceof THREE.Texture) {
                viewer.scene.scene.background.dispose();
            }
        }
        viewer.scene.scene.background = null;
    
        // Create a texture loader
        const loader = new THREE.TextureLoader();
    
        // Begin loading our panoramic texture stored in our aws folder
        const texture = loader.load(photoSphere.panorama_image, () => {
            // Create a new skybox render target
            const rt = new Skybox(texture.image.height);
    
            // Clear it
            rt.clear(viewer.renderer, true, true, true);
    
            // Set the size of our texture
            rt.setSize(texture.image.height, texture.image.height);
    
            // Load our texture and rotate the skybox to stitch to the lidar
            rt.fromEquirectangularTextureWithAngle(viewer.renderer, texture, photoSphere.initial_rotation);
    
            // Dispose of the old background texture if it exists
            if (viewer.scene.scene.background) {
                viewer.scene.scene.background.dispose();
            }
    
            // Set our scenes background to the render target's texture
            viewer.scene.scene.background = rt.texture;
    
            // Dispose of the previous texture to free memory
            texture.dispose();
        });
    
      
    }
    

    togglePanorama() {
        this.setState({ activePanorama: !this.state.activePanorama })
    }
    /**
     * @returns {Vector3} The center of the point cloud
     */
    getPointCloudCenter() {
        return this.pointCloudCenter;
    }

    takeScreenShot() {
        const canvases = document.querySelectorAll(' #potree_render_area canvas');

        const visibleCanvases = Array.from(canvases).filter((canvas) => {
            const style = window.getComputedStyle(canvas);

            return style.display !== 'none';
        });

        // Now visibleCanvases contains all the canvas elements that are visible
        if (visibleCanvases.length > 0) {
            const canvas = visibleCanvases[visibleCanvases.length - 1];
            // @ts-ignore
            const imageDataUrl = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.href = imageDataUrl;
            const date = 'Date: ' + new Date().toLocaleDateString() + ', Time: ' + new Date().toLocaleTimeString().replace(/:/g, '-').replace(/ /g, ' ');

            downloadLink.download = `${date}.png`;
            downloadLink.click();
        }
    }
    render() {
        return (
            <>
                <div style={{ position: "absolute", right: 0, top: 0, zIndex: 2, margin: 8 }}>
                    {this.state.cameraMode == CameraMode.PHOTO_SPHERE &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <Button type="primary" onClick={()=>this.enterFreeCamMode()}>
                            Enter Free Cam
                        </Button>
                        <Button type="primary" onClick={()=>this.takeScreenShot()}>
                            Take Screen Shot
                        </Button>
                        <Button type="primary" onClick={()=>this.togglePanorama()}>
                            {this.state.activePanorama ? 'Hide Background Image' :'Show Background Image'}
                        </Button>
                    
                    </div>
                    }
                </div>
                <div className="potree_container" id="potree-root" style={{ position: "absolute", height: "100%", width: "100%", left: 0, top: 0 }}>

                    <div id="potree_render_area" ref={this.potreeContainerDiv} >

                    </div>
                    <div id="potree_sidebar_container"> </div>
                </div>
            </>
        );
    }
}
